import type { DirectiveBinding } from 'vue';
import sanitizeHtml from 'sanitize-html';

// Directive that maps a string containing URLs so that the URLs are replaced by anchor tags. Only works on web links (not emails, phone numbers etc.).

// Source: https://github.com/component/regexps/blob/master/index.js#L3
// const regEx = /\b(?:(?:ht|f)tp(?:s?)\:\/\/|~\/|\/)?(?:\w+:\w+@)?((?:(?:[-\w\d{1-3}]+\.)+(?:com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|edu|co\.uk|ac\.uk|it|fr|tv|museum|asia|local|travel|[a-z]{2}))|((\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)(\.(\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)){3}))(?::[\d]{1,5})?(?:(?:(?:\/(?:[-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?(?:(?:\?(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)(?:&(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(?:#(?:[-\w~!$ |\/.,*:;=]|%[a-f\d]{2})*)?\b/gmi;
const regEx = /https?:\/\/[^\s<>()]+(?:\?[^\s<>()]*)?(?=$|\s|[\]}).,?!;])/gmi;

export default (el: HTMLElement, binding: DirectiveBinding) => {
    if (!binding.value) {
        return;
    }
    let text = String(binding.value);

    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    text = textArea.innerHTML;

    const parsedText = text.replaceAll(regEx, url => {
        try {
            const encodedUrl = encodeUrl(url);
            new URL(encodedUrl?.trim()); // Constructor will throw an exception if the URL is invalidy
            return `<a href="${encodedUrl}" target="_blank" rel="noopener noreferrer">${url}</a>`
        } catch (_) {
            return url;
        }
    });
    el.innerHTML = sanitizeHtml(parsedText, { nestingLimit: 1 });
};

function encodeUrl(url: string) {
    let [baseUrl, hash] = url.split('#');
    
    if (hash) {
        
        // Check for specific parameters within the fragment and encode them
        let fragment = hash.split('/');

        // Encode the individual parameters inside the fragment (like ChartDefinition and other query parameters)
        for (let i = 0; i < fragment.length; i++) {
            // Apply encoding to the parts that need it (e.g., JSON or special characters)
            if (/[{}":]/.test(fragment[i])) {
                fragment[i] = encodeURIComponent(fragment[i]);
            }
        }

        // Rebuild the fragment and append it to the base URL
        hash = fragment.join('/');

        // Return the final URL with the encoded fragment
        return baseUrl + '#' + hash;
    }

    return url;
}
